import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from "react-router-dom";
import AccountCircle from '@mui/icons-material/AccountCircle';
import {alpha, createTheme, styled, ThemeProvider, useTheme} from "@mui/material/styles";
import {CssBaseline, SvgIcon, Switch} from "@mui/material";
import ThemeToggler from "../theme/ThemeToggler";
// import { ReactComponent as Logo } from "./icon.svg";
import Logo from "./repeater.svg";
// import Logo from "./logo.png";
import {ColorModeContext} from "../config/ColorContext";
import InputBase from "@mui/material/InputBase";
import SearchIcon from '@mui/icons-material/Search';
import Divider from "@mui/material/Divider";

const pages = [{
  name: 'Status',
  url: "/dashboard",
}, {
  name: 'Settings',
  url: "/registers"
}];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 36,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    '&:hover': {
      backgroundColor: alpha("#fff", .0),    /*  10% opaque green */
    },
    transform: 'translateX(1px)',
    '&.Mui-checked': {
      color: '#000',
      transform: 'translateX(25px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="17" width="17" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
            theme.palette.mode === 'light' ? '#202020' : '#fff',
        )}" d="M8.972 6.661c0 6.044 4.806 10.965 10.804 11.15A9.749 9.749 0 0111.761 22C6.371 22 2 17.63 2 12.239c0-4.683 3.298-8.596 7.698-9.542a11.125 11.125 0 00-.726 3.964zm5.331-2.407L15.536 2l1.232 2.254 2.254 1.232-2.254 1.233-1.232 2.253-1.233-2.253-2.253-1.233 2.253-1.232zm4.754 6.725l.986-1.803.986 1.803 1.803.986-1.803.986-.986 1.803-.986-1.803-1.803-.986 1.803-.986z"/></svg>')`,
        // )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#202020' : '#E4E8F0',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#2C2C2C',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#2C2C2C',
    },
    width: 18,
    height: 18,
    // left: 5,
    marginTop: 8,
    marginLeft: 7,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 4,
      top: 4.5,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="17" width="17" viewBox="0 0 25 25"><path fill="${encodeURIComponent(
          theme.palette.mode === 'light' ? '#757575' : '#fff',
      )}" d="M12 19a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1zm-6.364-2.05a.999.999 0 111.414 1.414l-1.414 1.414a.997.997 0 01-1.414 0 .999.999 0 010-1.414zm11.314 0a.999.999 0 011.414 0l1.414 1.414a.999.999 0 11-1.414 1.414l-1.414-1.414a.999.999 0 010-1.414zM12 6c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6zm10 5a1 1 0 110 2h-2a1 1 0 110-2zM4 11a1 1 0 110 2H2a1 1 0 110-2zm14.364-6.779a1 1 0 011.414 1.414L18.364 7.05a.997.997 0 01-1.414 0 .999.999 0 010-1.414zm-14.142 0a1 1 0 011.414 0L7.05 5.636A.999.999 0 115.636 7.05L4.222 5.635a.999.999 0 010-1.414zM12 1a1 1 0 011 1v2a1 1 0 11-2 0V2a1 1 0 011-1z"/></svg>')`,
      // )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#202020' : '#e4e8f0',
    borderRadius: 30 / 2,
  },
}));
//
// export const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });
//
// const themeCustom = createTheme({
//   palette: {
//     mode: 'dark',
//     primary: {
//       light: '#757ce8',
//       main: '#ffffff',
//       dark: '#00ff00',
//       // dark: '#002884',
//       contrastText: '#37474F',
//       // "300": "#000000",
//       // "400": "#37474F",
//       // "500": "#37474F",
//     },
//     // secondary: {
//     //   light: '#ff7961',
//     //   main: '#f44336',
//     //   dark: '#ba000d',
//     //   contrastText: '#000',
//     // },
//     // text: {
//     //   primary: "#000000",
//     //
//     // }
//   },
// });

const ThemeSwitch = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return(<MaterialUISwitch onChange={colorMode.toggleColorMode} checked={theme.palette.mode === "dark"}></MaterialUISwitch>);
}

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (s) => s.zIndex.drawer + 1}}>
          <Toolbar>
            {/*<div style={{filter: invert(100%)}}>*/}
            <Box
                id="logo-box"
                component="img"
                sx={{
                  height: 46,
                  // color: "#00ff00",
                  filter: theme.palette.mode === "dark" ? "invert(0.825)" : "invert(0.28)",

                  // backgroundColor: "#00ff00",
                }}
                alt="Your logo."
                src={Logo}

            />
            {/*</div>*/}
            <Typography
                variant="h6"
                // noWrap
                // component="div"

                sx={{
                  flexGrow: 0,
                  // display: { xs: 'none', md: 'flex'},
                  fontFamily: 'Montserrat',
                    fontWeight: 600,
                    marginLeft: "20px",
                    // padding: 0,
                    // letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  fontStretch: "expanded",
                  fontOpticalSizing: "auto",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  // width: "200px"
                } }
            >
              {/*<SvgIcon>*/}
              {/*  <Logo />*/}
              {/*</SvgIcon>*/}
              przemienniki krótkofalarskie
              {/*<ThemeToggler />*/}
              {/*<Box sx={{ ml: 1, display: { xs: 'flex', md: 'flex' } }}>*/}

              {/*</Box>*/}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, color: "inherit" }}>
              <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
              >
                {pages.map((page) => (
                    <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                ))}
              </Menu>
            </Box>
            {/*<Typography*/}
            {/*    variant="h6"*/}
            {/*    noWrap*/}
            {/*    component="div"*/}
            {/*    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}*/}
            {/*>*/}
            {/*  RADIO-X*/}
            {/*</Typography>*/}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, color: "inherit" }}>

              {/*{pages.map((page) => (*/}
              {/*    // <ReactRouterDom.Link key={page+"-link"} to={`/${page.toLowerCase()}`}>*/}
              {/*      <Button*/}
              {/*          key={page.name}*/}
              {/*          onClick={() =>  {*/}
              {/*            handleCloseNavMenu();*/}
              {/*            // ReactRouterDom*/}
              {/*            // ReactRouterDom.useHref(`/${page.toLowerCase()}`);*/}

              {/*            navigate(page.url);*/}
              {/*          }}*/}
              {/*          sx={{ my: 2, color: 'inherit', display: 'block' }}*/}
              {/*      >*/}
              {/*        {page.name}*/}

              {/*      </Button>*/}
              {/*    // </ReactRouterDom.Link>*/}
              {/*))}*/}
            </Box>

            <Search>
              <SearchIconWrapper>
                <SearchIcon onClick={() => console.log("ok")} />
              </SearchIconWrapper>
              <StyledInputBase
                  placeholder="np. SR4X"
                  inputProps={{ 'aria-label': 'search' }}
              />
            </Search>

            <Box sx={{ flexGrow: 0, marginLeft: "20px", display: { xs: 'none', md: 'flex' } }}>
              {/*<Divider orientation="vertical" flexItem />*/}
              <ThemeSwitch  />
              {/*<Tooltip title="Open settings">*/}
              {/*  <IconButton onClick={handleOpenUserMenu} size="large"*/}
              {/*              aria-label="account of current user"*/}
              {/*              aria-controls="menu-appbar"*/}
              {/*              aria-haspopup="true"*/}
              {/*              color="inherit">*/}
              {/*    <AccountCircle />*/}
              {/*  </IconButton>*/}
              {/*</Tooltip>*/}
              <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
      </AppBar>
        {/*<Divider/>*/}
      </Box>
  );
};
export default ResponsiveAppBar;