import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { deepmerge } from '@mui/utils';
import {
    createTheme,
    CssBaseline,
    ListItemText, PaletteMode, responsiveFontSizes, ThemeProvider, useMediaQuery
} from "@mui/material";
import ResponsiveAppBar, {MaterialUISwitch} from "./components/ResponsiveAppBar";
import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Registers from "./pages/Registers";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import MainAppBar from "./components/MainAppBar";
import {getDesignTokens, getTheme, getThemedComponents} from "./theme";
import { ColorModeContext } from './config/ColorContext';
import {ThemeDark} from "./theme/ThemeDark";
import ThemeToggler from './theme/ThemeToggler';
import Grid from "@mui/material/Grid";
import MainMap from "./pages/MainMap";
import Divider from "@mui/material/Divider";
import {UseDataContext} from "./config/DataContext";
import {useEffect} from "react";

function Copyright() {
  return (
      <>
    <Typography variant="body2" color="text.secondary" align="center">
      {`${new Date().getFullYear()} © `}
      <Link color="inherit" href="https://github.com/mkopa">
        przemienniki.net.pl
      </Link>
    </Typography>

          {/*<MaterialUISwitch sx={{ mr: 2 }} size="small" />*/}
    {/*<ThemeToggler />*/}
      </>
  );
}

export default function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = React.useState<PaletteMode>(prefersDarkMode ? 'dark' : 'light');

    const {
        global:{
            globalValue
        },
        apiValue:{
            apidata, setApiData
        }
    } = UseDataContext();

    useEffect(()=>{
        // fetchData.then((response)=>{
            setApiData({});
        // });
    },[]);

    React.useEffect(() => {
        setMode(prefersDarkMode ? 'dark' : 'light');
    }, [prefersDarkMode]);

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        []
    );

    let theme = React.useMemo(
        () =>
            // createTheme(deepmerge(getDesignTokens(mode), getThemedComponents(mode))),
            createTheme(getTheme(mode)),
        // getTheme(mode),
            // createTheme(getThemedComponents(mode)),
        // getThemedComponents(mode),
        [mode]
    );

    theme = responsiveFontSizes(theme);
    // const theme2 = createTheme(ThemeDark);

  return (
      <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme={true} />
        {/*<Container fixed>*/}
        {/*<Box*/}
        {/*    sx={{*/}
        {/*        display: 'flex',*/}
        {/*        flexDirection: 'column',*/}
        {/*        // minHeight: '100vh',*/}
        {/*    }}*/}
        {/*>*/}
              <ResponsiveAppBar></ResponsiveAppBar>
              <Divider />
              <ResponsiveAppBar></ResponsiveAppBar>
              {/*<Divider />*/}
            {/*<MainAppBar />*/}

        {/*<div>*/}
        {/*    {(['left', 'right', 'top', 'bottom'] as const).map((anchor) => (*/}
        {/*        <React.Fragment key={anchor}>*/}
        {/*            <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
        {/*            <Drawer*/}
        {/*                anchor={anchor}*/}
        {/*                open={state[anchor]}*/}
        {/*                onClose={toggleDrawer(anchor, false)}*/}
        {/*            >*/}
        {/*                {list(anchor)}*/}
        {/*            </Drawer>*/}
        {/*        </React.Fragment>*/}
        {/*    ))}*/}
        {/*</div>*/}

{/*<Container>*/}
        <Routes>
            <Route path="/" element={<MainMap />}></Route>
                {/*<Route index element={<Dashboard />} />*/}

                <Route path="/dashboard" element={<Navigate replace to="/" />} />

                {/*<Route path="teams" element={<Teams />}>*/}
                {/*    <Route path=":teamId" element={<Team />} />*/}
                {/*    <Route path="new" element={<NewTeamForm />} />*/}
                {/*    <Route index element={<LeagueStandings />} />*/}
                {/*</Route>*/}

            <Route path="/registers" element={<Registers />} />
        </Routes>
{/*</Container>*/}
    {/*</BrowserRouter>*/}


            <Box
                component="footer"
                sx={{
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    width: "100vw",
                    zIndex: (s) => s.zIndex.drawer + 2,
                    py: 0,
                    px: 0,
                    mt: 'auto',
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? "#fff"
                            : "#2c2c2c",
                }}
            >
                <Copyright />
                {/*<Container maxWidth="sm">*/}
                    {/*<Grid container*/}
                    {/*      direction="row"*/}
                    {/*      spacing={3}*/}
                    {/*      justifyContent="space-between"*/}
                    {/*      alignItems="flex-end">*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        xs=6 md=8*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        xs=6 md=4*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={4} justifyContent="right">*/}
                    {/*        <MaterialUISwitch sx={{ mr: 2 }} size="small" />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    {/*<Typography variant="body1">*/}
                    {/*</Typography>*/}
                    {/*<Copyright />*/}

                {/*</Container>*/}
            </Box>
        {/*</Box>*/}
        {/*</Container>*/}
          </ThemeProvider>
      </ColorModeContext.Provider>
  );
}
