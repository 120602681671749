import React, {Component, useContext, useState} from "react";
import rxfXml from "../exports/przemienniki.net/xml/rxf.xml";
import PropTypes from "prop-types";

export interface AppData {
    apiValue:{
        apiData: string[];
        setApiData: (apiData: string[]) => string[];
    },
    global:{
        globalValue: string;
    }
};

export const DataContext = React.createContext<any>(undefined);

// Hook to provide access to context object
export const UseDataContext = () => {
    return useContext(DataContext);
};

export const DataContextProvider = (props: any) => {
    const [apiData, setApiData] = useState([]);
    const globalValue = "Global Value"

    // Assign React state and constants to context object
    const DataContextObject = {
        apiValue:{
            apiData, setApiData
        },
        global:{
            globalValue
        }
    };
    return (
        <DataContext.Provider value={DataContextObject}>
            {props.children}
        </DataContext.Provider>
    );
};

DataContextProvider.propTypes = {
    children: PropTypes.element,
};