import {useEffect, useRef, useState} from "react";
import * as React from "react";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import TileLayer from "ol/layer/Tile";
import {OSM, Stamen, Vector} from "ol/source";
import {Icon, Style} from "ol/style";
import XYZ from "ol/source/XYZ";
import Map from "ol/Map";
import View from "ol/View";
import {fromLonLat, transform} from "ol/proj";
import {Geometry, Point} from "ol/geom";
import {Feature} from "ol";

export function MapView(props: any) {
    const [ map, setMap ] = useState<Map>()
    const [ featuresLayer, setFeaturesLayer ] = useState<VectorLayer<VectorSource<Geometry>>>()
    const [ selectedCoord , setSelectedCoord ] = useState()

    // pull refs
    const mapElement = useRef() as React.MutableRefObject<HTMLInputElement>;

    // create state ref that can be accessed in OpenLayers onclick callback function
    //  https://stackoverflow.com/a/60643670
    const mapRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    mapRef.current = map as any;

    // initialize map on first render - logic formerly put into componentDidMount
    useEffect( () => {
        // axios.get(rxfXml, {
        //     headers: {
        //         "Content-Type": "application/xml; charset=utf-8"
        //     }
        // })
        //     .then((response) => {
        //         console.log('Your xml file as string', response.data);
        //     });
        // create and add vector source layer
        // const initalFeaturesLayer = new VectorLayer({
        //     // source: new BingMaps()
        //     source: new VectorSource()
        // });

        var tonerLayer =new TileLayer({
            className: "inv",
            source: new Stamen({
                layer: 'toner',
            }),
        });
        const waterLayer = new TileLayer({
            source: new Stamen({
                layer: 'watercolor',
            }),
        });



        const tileSource = new XYZ({
            wrapX: true,
            // imageSmoothing: false,
            // maxResolution: 1,
            url: 'https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
            //   url: 'https://tile.openstreetmap.org/${z}/${x}/${

        });

        const tileOsm = new TileLayer({

            // type: 'base',
            // title: 'Topographic',
            visible: true,
            source: new OSM(),

            // source: tileSource,
        });

        const tileGoogleMaps = new TileLayer({

            // type: 'base',
            // title: 'Topographic',
            visible: true,
            // source: new OSM(),
            source: tileSource,

        });
        const iconFeature = new Feature({
            geometry: new Point(fromLonLat([53.099843380016386, 23.10748336012388].reverse())),
            name: 'Null Island',
            population: 4000,
            rainfall: 500,
        });
        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: './repeater.svg',
                scale: [0.15, 0.15],
                // scale([0,0]),
            }),
        });

        iconFeature.setStyle(iconStyle);
        // const iconStyle = new Style({
        //     ba
        // });
        // iconFeature.setStyle(iconStyle);
        var initalFeaturesLayer = new VectorLayer({
            source: new Vector({
                features: [iconFeature,
                    // new Feature({
                    //
                    //     // geometry: new Point(fromLonLat([53.097843380016386, 23.20748336012388])),
                    //     geometry: new Point(fromLonLat([23.20748336012388, 53.097843380016386])),
                    //     // geometry: new Point([2168538.3863093588, 6812669.51890163],),
                    //
                    //     html: '<b>Barrow Hill and Tansey Green</b><br />I am a popup. 1',
                    //     name: "elo",
                    //     population: 4000,
                    //     rainfall: 500,
                    // }),
                    // new Feature({
                    //     geometry: new Point(fromLonLat([53.099843380016386, 23.10748336012388].reverse())),
                    //     html: '<b>Barrow Hill and Tansey Green</b><br />I am a popup. 2'
                    // }),
                    // new Feature({
                    //     geometry: new Point(fromLonLat([53.097843380016386, 23.10748336012388].reverse())),
                    //     html: '<b>Barrow Hill and Tansey Green</b><br />I am a popup. 3'
                    // })
                ]
            })
        });

        // create map
        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                tileGoogleMaps,
                // initalFeaturesLayer,
                initalFeaturesLayer,
            ],
            view: new View({
                projection: 'EPSG:3857',
                center: [2168538.3863093588, 6812669.51890163],
                zoom: 7,
                // smoothExtentConstraint: false,
                // smoothResolutionConstraint: false,
                // resolutions: tileSource?.getTileGrid()?.getResolutions(),
            }),
            // target: "map",
            controls: []
        })

        // set map onclick handler
        initialMap.on('click', handleMapClick)

        // save map and vector layer references to state
        setMap(initialMap);
        setFeaturesLayer(initalFeaturesLayer);


        // map?.getView().fit((initalFeaturesLayer as any).getSource().getExtent(), {
        //     padding: [40, 16, 40, 16]
        // });
    },[])

    // update map if features prop changes - logic formerly put into componentDidUpdate
    useEffect( () => {

        if (props?.features?.length) { // may be null on first render

            // set features to map
            (featuresLayer as any).setSource(
                new VectorSource({
                    features: props.features // make sure features is an array
                })
            )

                // fit map to feature extent (with 100px of padding)
                map?.getView().fit((featuresLayer as any).getSource().getExtent(), {
                padding: [100,100,100,100]
            })
            // map?.getView().fit((marker as any).getSource().getExtent(), {
            //     padding: [40, 16, 40, 16]
            // });

        }

    },[props.features])

    // map click handler
    const handleMapClick = (event:any) => {

        // get clicked coordinate using mapRef to access current React state inside OpenLayers callback
        //  https://stackoverflow.com/a/60643670
        const clickedCoord = (mapRef.current as any).getCoordinateFromPixel(event.pixel);

        // transform coord to EPSG 4326 standard Lat Long
        // const transormedCoord = transform(clickedCoord, 'EPSG:3857', 'EPSG:4326')
        const transormedCoord = transform(clickedCoord, 'EPSG:3857', 'EPSG:3857')

        // set React state
        setSelectedCoord( transormedCoord as any)

        console.log("transform: |", transormedCoord);

        map?.updateSize();

        map?.getView().fit((featuresLayer as any).getSource().getExtent(), {
            padding: [40, 16, 40, 16]
        });


    }

    // render component
    return (
        <div ref={mapElement}  className="map-container"></div>
    )

}