import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
// import theme from './theme';
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import {DataContextProvider} from "./config/DataContext";

const rootElement = document.getElementById('root');
// const root = ReactDOM.c.createRoot(rootElement!);

ReactDOM.render(
    <div>
        <CssBaseline enableColorScheme={true} />
      <BrowserRouter>
    <DataContextProvider>
    <App />
    </DataContextProvider>
      </BrowserRouter>
    </div>,
  // </ThemeProvider>,
    rootElement
);
