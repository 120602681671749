import * as React from 'react';
import './MainMap.css';
import Box from "@mui/material/Box";
import { styled} from "@mui/material/styles";
import {Drawer, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {MapView} from "../components/MapView";



// styled
const LayoutContainer = styled('div')(() => ({
    // height: '100%',
    // overflow: 'hidden',
    // width: '100%'
    margin: 0,
}));

const drawerWidth = 240;

export default function MainMap() {

  return (
      <LayoutContainer>
      {/*<div style={{ width: '100%' }}>*/}
      {/*    <Box sx={{ bgcolor: 'black'}}>*/}
      {/*        <Container>*/}
      {/*            My content*/}
      {/*        </Container>*/}
          {/*</Box>*/}
          <Drawer
              variant="persistent"
              // hidden={false}
              open={false}
              sx={{
                  backgroundColor: "#000",
                  // width: drawerWidth,
                  // flexShrink: 0,
                  // marginTop: "100px",
                  // padding: 0,
                  // width: drawerWidth,
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
              }}
          >
              <Toolbar />
              <Box sx={{ overflow: 'auto', boxShadow: "none", backgroundColor: "inherit" }}>
                  <Divider />
                  <List>
                      {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                          <ListItem key={text} disablePadding>
                              <ListItemButton>
                                  <ListItemIcon>
                                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                  </ListItemIcon>
                                  <ListItemText primary={text} />
                              </ListItemButton>
                          </ListItem>
                      ))}
                  </List>
                  <Divider />
                  <List>
                      {['All mail', 'Trash', 'Spam'].map((text, index) => (
                          <ListItem key={text} disablePadding>
                              <ListItemButton>
                                  <ListItemIcon>
                                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                  </ListItemIcon>
                                  <ListItemText primary={text} />
                              </ListItemButton>
                          </ListItem>
                      ))}
                  </List>
              </Box>
          </Drawer>
          <MapView />

      {/*</div>*/}
      </LayoutContainer>
  );
}
