import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {FormControl, InputLabel, Select, SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Grid from "@mui/material/Grid";


const columns: GridColDef[] = [
    { field: 'id', headerName: 'Register', width: 90, disableColumnMenu: true, align: "center", headerAlign: "center" },
    // {
    //     field: 'firstName',
    //     headerName: 'First name',
    //     width: 150,
    //     editable: true,
    // },
    // {
    //     field: 'lastName',
    //     headerName: 'Last name',
    //     width: 150,
    //     editable: true,
    // },
    {
        field: 'age',
        headerName: 'Value (HEX)',
        type: 'string',
        width: 110,
        editable: true,
        sortable: false,
        filterable: false,
        hideable: false,
        disableColumnMenu: true,
        align: "center",
        headerAlign: "center",
    },
    {
        field: 'fullName',
        headerName: 'Description',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        // width: 2000,
        // align: "right",
        // headerAlign: "right",
        editable: false,
        resizable: true,
        disableColumnMenu: true,
        minWidth: 300,
        maxWidth: 3000,
        flex: 1,


        valueGetter: (params: GridValueGetterParams) =>
            `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
];

const rows = [
    { id: "0x01", lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function Registers() {

    const [age, setAge] = React.useState('0x11');

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };

  return (
      <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={50}
            hideFooter={true}
            // pagination={false}
            // pagination="false"
            // rowsPerPageOptions={[5]}
            // checkboxSelection
            disableSelectionOnClick
            disableColumnFilter
            disableVirtualization
            paginationMode="client"
            autoHeight={true}
            autoPageSize={true}
            density="compact"
            disableExtendRowFullWidth={false}
        />
              </div>
          </div>
      </div>
  );
}
